import React from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { authLogout } from "../store/actions/auth";
import axios from "src/store/_shared/authAxios";
import { default as cleanAxios } from "axios";
import Button from "@mui/material/Button";
import { DismissibleToast } from "src/components/GenericAlert";
import { Copy as CopyIcon } from "react-feather";

const rootPath = window.ini.REACT_APP_ROOT_PATH;

export const handleCopyClick = (e, contentsToCopy) => {
  e.preventDefault();
  e.stopPropagation();

  if (typeof contentsToCopy === "string") {
    navigator.clipboard.writeText(contentsToCopy)
      .then(() => {
        toast.success((t) => (<DismissibleToast message="Copied to clipboard" id={t.id} />), { duration: 6000, autoDismiss: false });
      })
  }
};

const AxiosAlert = props => {
  const history = useHistory();

  const handlers = [
    function (response) {
      return response;
    },
    function (error) {
      console.log("AxisoError", error);
      if (error && error.response && error.response.config) {
        if (error.response.config.url.indexOf("adminCore/bonus/award") > -1) {
          if (error.response.config.data) {
            try {
              const data = JSON.parse(error.response.config.data);
              if (data && data.sourceId && (data.sourceId.toString() === "7" || data.sourceId.toString() === "8"))
                return Promise.reject(error);
            } catch (err) {
              /*noop*/
            }
          }
        } else if (
          error.response.config.url.indexOf("authorization/v1/refresh") > -1 ||
          error.response.config.url.indexOf("internal/sync/records/get-single") > -1 ||
          error.response.config.url.indexOf("/domain/check-external") > -1 ||
          error.response.config.url.indexOf("/domain/check-available") > -1 ||
          error.response.config.url.indexOf("/domain/by-name") > -1 ||
          error.response.config.url.indexOf("/api/wl-content-management/v1/definition/list-types") > -1 ||
          error.response.config.url.indexOf("/api/wl-platform/v1/attribute/list") > -1 ||
          error.response.config.url.indexOf("/adminapi/adminCore/player/documents/action/scanFile") > -1 ||
          error.response.config.url.indexOf("/adminapi/adminCore/player/documents/action/updateFromDruid") > -1 ||
          error.response.config.url.indexOf("/adminapi/adminCore/player/account/update") > -1
        ) {
          return Promise.reject(error);
        }
      }

      if (error && error.response && error.response.status === 403) {
        toast.error((t) => (<DismissibleToast message="Login session expired" id={t.id} />), { duration: 6000, autoDismiss: false });

        props.authLogout();
        history.push(`${rootPath}/home`);
        return Promise.reject(error);
      }

      let msg, url, contentsToCopy = [];

      if (error && error.response && error.response.config) {
        url = error.response.config.method.toUpperCase() + " " + error.response.config.url;
        msg = error.response.data && error.response.data.error ? error.response.data.error : error.toString();
      } else {
        url = "";
        msg = error.toString();
      }

      if (msg) {
        let txtMsg = msg;
        if (typeof msg !== "string") {
          txtMsg = JSON.stringify(msg);
        }
        if (txtMsg.indexOf("CanceledError") > -1) return Promise.reject(error);

        if (txtMsg.indexOf("Network Error") > -1) {
          if (error && error.config && error.config.url && !url) {
            url = error.config.method.toUpperCase() + " " + error.config.url;
          }
        }

        if (url) {
          contentsToCopy.push(url);
        }
        if (typeof msg !== "string") {
          contentsToCopy.push(JSON.stringify(msg, null, 2));
        } else {
          contentsToCopy.push(msg);
        }
        if (contentsToCopy.length) {
          const now = moment();
          const formattedDateTime = now.format('YYYY-MM-DD HH:mm:ssZ');
          const unixTimestamp = now.unix();
          contentsToCopy.splice(0, 0, `${formattedDateTime} [${unixTimestamp}]`);
        }

        toast.error(
          (t) => <DismissibleToast
            message={(
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!!url && <div className="text-nowrap">{url}</div>}
                <div className={!!url ? "mt-2" : ""}>
                  <Button
                    onClick={(e) => handleCopyClick(e, contentsToCopy.length ? contentsToCopy.join(" - ") : null)}
                    color="secondary"><CopyIcon size={14} /></Button>
                  {typeof msg !== "string" && <pre>{JSON.stringify(msg, null, 2)}</pre>}
                  {typeof msg === "string" && <div>{msg}</div>}
                </div>
              </div>)}
            id={t.id}
          />,
          {
            duration: 6000,
            autoDismiss: false,
          }
        );
      }

      return Promise.reject(error);
    }
  ];

  React.useEffect(() => {
    const myInterceptor = axios.interceptors.response.use(...handlers);
    const cleanInterceptor = cleanAxios.interceptors.response.use(...handlers);
    return () => {
      axios.interceptors.response.eject(myInterceptor);
      cleanAxios.interceptors.response.eject(cleanInterceptor);
    };
  }, []); // eslint-disable-line

  return null;
};

const actionCreators = {
  authLogout,
};

export default connect(null, actionCreators)(AxiosAlert);
