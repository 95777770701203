import { produce } from "immer";
import { adminSettingsConstants } from "../constants";

const initialState = {
  customerSearchSettings: {},
};

const adminSettingsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case adminSettingsConstants.LOADED: {
        // console.log("adminSettingsReducer", action.data);
        draft.customerSearchSettings = action.data?.customerSearchSettings || {};
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default adminSettingsReducer;