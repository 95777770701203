import { bulkTagsGrant } from "../constants";

export const bulkTagsGrantStart = data => ({
  type: bulkTagsGrant.START,
  data
});

export const bulkTagsGrantDone = (data) => ({
  type: bulkTagsGrant.DONE,
  data
});

export const bulkTagsGrantClear = () => ({
  type: bulkTagsGrant.CLEAR,
});

export const bulkTagsGrantProgress = () => ({
  type: bulkTagsGrant.PROGRESS
});
