import {all, call, put, takeEvery, delay} from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "../../store";
import {bulkTagsGrant} from "../constants";
import {bulkTagsGrantDone} from "../actions/bulk-tags-grant";
import {buildApiUrl} from "../../utils/api";

const apiCoreUrl = buildApiUrl("/adminCore");

function* processSingleRequest(requestData, currentRequest) {
  const randomStartDelay = Math.random() * 500;  // between 0-500ms
  yield delay(randomStartDelay);

  let attempts = 0;
  let shouldRetry = true;
  const maxRetries = window.ini?.REACT_APP_BULK_TOOL_MAX_RETRIES ?? bulkTagsGrant.MAX_RETRIES;

  while (attempts < maxRetries && shouldRetry) {
    try {
      console.log(`Processing request ${currentRequest}... Attempt ${attempts + 1}`);
      yield call(axios.post, `${apiCoreUrl}/player/tags/addTag`, requestData);
      return {
        success: true,
        requestData,
        indexInChunk: currentRequest,
      };
    } catch (e) {
      attempts++;
      console.error(`Processing request ${currentRequest}... Attempt ${attempts} failed: ${e.toString()}`);

      shouldRetry = e?.response?.status === 500;

      if (!shouldRetry || attempts >= maxRetries) {

        let message = e.toString();
        if (e?.response?.data?.error?.response) {
          const {ResponseCode, ResponseMessage} = e.response.data.error.response;
          if (ResponseCode || ResponseMessage) {
            message = `${ResponseMessage}${ResponseCode ? ` (ErrorCode: ${ResponseCode})` : ''}`;
          }
        }
        return {
          success: false,
          message,
          requestData,
          indexInChunk: currentRequest,
        };
      }

      // Calculate exponential backoff delay with jitter
      const baseDelay = 1000;  // Initial delay (1s)
      const backoffDelay = baseDelay * 2 ** (attempts - 1);  // Exponential backoff
      const jitter = Math.random() * 500;  // Random jitter (0-500 ms)

      yield delay(backoffDelay + jitter);
    }
  }
}

function* bulkTagsGrantProgressSaga(action) {
  //processing current chunk
  const startTime = +new Date(); // Track global start time
  const { bulkTagsGrant: state } = store.getState();
  const { chunks, currentChunk } = state;

  console.warn("Saga: Progressing bulk bonus grant", {currentChunk});

  if (currentChunk >= chunks.length) {
    yield put(bulkTagsGrantDone({
      results: [],
      currentChunk: currentChunk,
      elapsedTime: 0,
    }));
    return;
  }

  const chunkStartTime = +new Date();
  const results = yield all(
    chunks[currentChunk].map((requestData, index) =>
      // bulkTagsGrant.MAX_CONCURRENT_REQUESTS = 1, as each request is already a batch of updates
      call(processSingleRequest, requestData, currentChunk * bulkTagsGrant.MAX_CONCURRENT_REQUESTS + index)
    )
  );
  const chunkEndTime = +new Date();
  const totalElapsedTime = chunkEndTime - startTime;
  console.log("Chunk processed. Chunk time:", chunkEndTime - chunkStartTime, "ms");
  console.log("Total elapsed time:", totalElapsedTime, "ms");

  yield put(bulkTagsGrantDone({
    results: results.map((result, index) => ({
      ...result,
    })),
    currentChunk: currentChunk,
    elapsedTime: totalElapsedTime,
  }));
}


export default function* watchBulkTagsGrantSaga() {
  yield takeEvery(bulkTagsGrant.PROGRESS, bulkTagsGrantProgressSaga);
}
