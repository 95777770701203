import { takeEvery, put } from "redux-saga/effects";

import { userConstants } from "../constants";
import { authSuccess, authError, authInProgress } from "../actions/auth";
import { doCasinoAuthentication } from "../actions/casino";
import { tenantsFetchAll } from "../actions/tenants";
import { sitesFetchAll } from "../actions/sites";
import { loadSelects } from "../actions/selects";
import { loadLotteries } from "../actions/lotteries";
import { loadGames } from "../actions/games";
import { loadTables } from "../actions/live-casino";
import { loadCountryFlags } from "../actions/country-flags";
import { loadAdminSettings } from "../actions/admin-settings";

import { dataTypesDefRequest } from "../actions/data";
import {
  contentManagementTypesDefRequest,
  contentManagementAttributesListRequest,
} from "../actions/content-management";
import { authService } from "../_shared/_redux/authServices";

function* authCheckSaga() {
  yield put(authInProgress(true));
  try {
    const data = yield authService.check();
    yield put(authSuccess(data));
    yield put(tenantsFetchAll());
    yield put(sitesFetchAll());
    yield put(doCasinoAuthentication());
    yield put(loadSelects());
    yield put(loadGames());
    yield put(loadLotteries());
    yield put(loadTables());
    yield put(dataTypesDefRequest());
    yield put(contentManagementTypesDefRequest());
    yield put(contentManagementAttributesListRequest());
    yield put(loadCountryFlags());
  } catch (e) {
    console.error(e);
    yield put(authError(e.response && e.response.data ? e.response.data : e.toString()));
  }
  yield put(authInProgress(false));
}

export default function* watchAuthSaga() {
  yield takeEvery(userConstants.AUTH_CHECK_REQUEST, authCheckSaga);
}
