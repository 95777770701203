import { adminSettingsConstants } from "../constants";

export const loadAdminSettings = (data) => ({
  type: adminSettingsConstants.LOAD,
  data
});

export const loadedAdminSettings = (data) => ({
  type: adminSettingsConstants.LOADED,
  data: data
});