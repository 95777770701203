import { takeEvery, put } from "redux-saga/effects";

import { adminSettingsConstants } from "src/store/constants";
import {loadedAdminSettings} from "src/store/actions/admin-settings";
import store from "src/store";
import axios from "src/store/_shared/authAxios";
import {buildApiUrl} from "src/utils/api";

const apiUrl = buildApiUrl("/admin");

function* loadAdminSettingsSaga(action) {
  try {
    const {tenants} = store.getState();
    // console.log("[SAGA] loadAdminSettingsSaga", tenants);

    const regKey = "customerSearchSettings";
    const allowedTenants = yield axios.get(`${apiUrl}/registry/tenants?key=${regKey}`);

    const tenantId = allowedTenants?.data?.includes(tenants.currentTenant) ? tenants.currentTenant : 0;

    const promises = [];
    promises.push(axios.post(`${apiUrl}/registry/load?tenant_id=${tenantId}`, {
      key: regKey,
    }).catch(e => e));

    const [
      customerSearchSettings,
    ] = yield Promise.all(promises);

    yield put(loadedAdminSettings({
      customerSearchSettings: customerSearchSettings?.data  || {},
    }));
  } catch (e) {
    console.log("[SAGA] loadAdminSettingsSaga error", e.toString());
  }
}

export default function* watchAdminSettingsSaga() {
  // console.log("[SAGA] watching AdminSettings");
  yield takeEvery(adminSettingsConstants.LOAD, loadAdminSettingsSaga);
}
