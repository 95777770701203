import React from 'react';
import toast from 'react-hot-toast';
import axios from "axios";
import { connect } from "react-redux";

import MuiAlert from "./Alert";
import evBus from "../utils/evBus";
import { appHideVersionCheckModal, appBuildInfoReceived } from "../store/actions/app";
import { ReactComponent as TriangleIcon } from "src/components/ui/assets/triangle.svg";

const PUBLIC_URL = import.meta.env.BASE_URL;

export const DismissibleToast = ({ message, id, className }) => {
  const handleClick = (e) => {
    if (!window.getSelection().toString()) {
      e.stopPropagation();
      toast.dismiss(id);
    }
  };

  return (
    <div onClick={handleClick} className={className ? className : ''}>
      {message}
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GenericAlert = (props) => {
  const { thisVersion, remoteVersion, newVersionAvailable, hideAlert, buildInfoReceived } = props;
  const [state, setState] = React.useState({
    open: false,
    msg: "",
    autoHideDuration: 6000,
    severity: "success", // success; info; error & warning
    version: false
  });

  const handleEvent = (data, event) => {
    if (event === "GENERIC_ALERT") {
      const newState = {
        open: true,
        msg: "",
        severity: "success",
        autoHideDuration: 6000,
        version: false
      };

      if (data.msg) {
        newState.msg = data.msg;
        if (data.severity) newState.severity = data.severity;
        if (data.autoHideDuration) newState.autoHideDuration = parseInt(data.autoHideDuration);
        if (data.version) newState.version = true;
      }

      switch (newState.severity) {
        case "success":
          toast.success((t) => (<DismissibleToast message={<div dangerouslySetInnerHTML={{ __html: newState.msg }} />} id={t.id} />), { duration: data.autoHideDuration });
          break;
        case "info":
          toast.success((t) => (<DismissibleToast message={<div dangerouslySetInnerHTML={{ __html: newState.msg }} />} id={t.id} />), { duration: data.autoHideDuration });
          break;
        case "info-normal":
          toast.success((t) => (<DismissibleToast message={<div dangerouslySetInnerHTML={{ __html: newState.msg }} />} id={t.id} />), { duration: data.autoHideDuration, icon: null });
          break;
        case "warning":
        case "critical":
          toast.success((t) => (<DismissibleToast message={<div dangerouslySetInnerHTML={{ __html: newState.msg }} />} id={t.id} />), {
            duration: data.autoHideDuration, icon: <div
              className="toast-icon warning"><TriangleIcon /></div>
          });
          break;
        case "error":
          toast.error((t) => (<DismissibleToast message={<div dangerouslySetInnerHTML={{ __html: newState.msg }} />} id={t.id} />), { duration: data.autoHideDuration });
          break;
      }
    }
  };

  React.useEffect(() => {
    evBus.on("GENERIC_ALERT", handleEvent);

    return () => {
      evBus.remove("GENERIC_ALERT", handleEvent);
    };
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (!thisVersion) {
      return;
    }

    const tid = setInterval(() => {
      axios.get(PUBLIC_URL + "static/config/version.json?_=" + (+new Date())).then(res => {
        buildInfoReceived(res.data);
      });
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(tid);
    }
  }, [thisVersion, buildInfoReceived]);

  React.useEffect(() => {
    if (newVersionAvailable) {
      // trigger version changed alert
      handleEvent(
        {
          msg: `New website version available. Refresh page! (${remoteVersion.commit} vs ${thisVersion.commit})!`,
          severity: "info",
          autoHideDuration: 60 * 60 * 1000, // 1h
          version: true,
        },
        "GENERIC_ALERT"
      );
    }
  }, [remoteVersion, thisVersion, newVersionAvailable]);

  return null;
}

const mapStateToProps = (state) => {
  return {
    thisVersion: state.app.thisVersion,
    remoteVersion: state.app.remoteVersion,
    newVersionAvailable: state.app.newVersionAvailable,
    versionModalShown: state.app.versionModalShown,
  }
};

const actionCreators = {
  hideAlert: appHideVersionCheckModal,
  buildInfoReceived: appBuildInfoReceived,
};

export default connect(mapStateToProps, actionCreators)(GenericAlert);